<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入商品名称" style="width: 220px;" class="filter-item" clearable/>
      <el-select class="filter-item" filterable v-model="listQuery.canteen_id" placeholder="请选择食堂" style="width: 140px" clearable>
        <el-option v-for="item in canteens" :key="item.id" :label="item.canteen_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="请选择店铺" style="width: 140px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" filterable v-model="listQuery.category_id" placeholder="请选择分类" style="width: 140px" clearable>
        <el-option v-for="item in categories" :key="item.id" :label="item.category_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.is_show" placeholder="请选择状态" style="width: 140px" clearable>
        <el-option label="已上架" :value="1"></el-option>
        <el-option label="已下架" :value="0"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>
    </div>
    <div class="filter-container">
      <el-button-group>
        <el-button type="primary" size="small" @click="handleShow(1)" :disabled="ids.length <= 0">批量上架</el-button>
        <el-button type="warning" size="small" @click="handleShow(0)" :disabled="ids.length <= 0">批量下架</el-button>
        <el-button type="danger" size="small" @click="handleDel" :disabled="ids.length <= 0">批量删除</el-button>
        <el-button type="info" size="small" @click="handleCopy" :disabled="ids.length <= 0">复制添加</el-button>
      </el-button-group>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="40"></el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop.shop_name }}
        </template>
      </el-table-column>
      <el-table-column label="分类" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.category.category_name }}
        </template>
      </el-table-column>
      <el-table-column label="商品名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.goods_name }}
        </template>
      </el-table-column>
      <el-table-column label="描述" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.goods_description }}
        </template>
      </el-table-column>
      <el-table-column label="封面" min-width="110" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.goods_thumb" class="user-avatar">
        </template>
      </el-table-column>
      <el-table-column label="权重" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>
      <el-table-column label="库存" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.goods_number }}
        </template>
      </el-table-column>
      <el-table-column label="上下架" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.is_show == 1">已上架</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="primary" @click="handleDownload(scope.row)">二维码</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="店铺">
          <el-select :disabled="form.id ? true : false" class="filter-item" v-model="form.shop_id" placeholder="请选择店铺" style="width: 140px" clearable>
            <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="form.id ? true : false" class="filter-item" v-model="form.category_id" placeholder="请选择分类" style="width: 140px" clearable>
            <el-option v-for="item in categoriesCopy" :key="item.id" :label="item.category_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="form.goods_name" placeholder="请输入商品名称" />
        </el-form-item>
        <el-form-item label="商品描述">
          <el-input v-model="form.goods_description" placeholder="请输入商品描述" />
        </el-form-item>
        <el-form-item label="封面">
          <upload-one v-model="form.goods_thumb"></upload-one>
          <div style="font-size:12px;color:red;">*推荐尺寸：160*160</div>
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="商品价格">
              <el-input v-model="form.sell_price" type="number" min="0" placeholder="请输入商品价格" />
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="包装费">
              <el-input v-model="form.pack_fee" type="number" min="0" placeholder="请输入包装费" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="库存">
              <el-input v-model="form.goods_number" type="number" min="0" placeholder="请输入库存" />
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="权重">
              <el-input v-model="form.weight" type="number" min="0" placeholder="请输入库存" />
            </el-form-item>
          </el-col>
        </el-row>
<!--        <el-form-item label="是否必选">-->
<!--          <el-switch v-model="form.is_require" :active-value="1" :inactive-value="0"></el-switch>-->
<!--        </el-form-item>-->
        <el-form-item label="规格">
          <el-button icon="el-icon-plus" size="small" plain @click="handelSpecAdd"></el-button>
          <el-table size="mini" :data="form.specs" border fit>
            <el-table-column label="名称" align="center" min-width="120">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.spec_name" />
              </template>
            </el-table-column>
            <el-table-column label="价格(元)" align="center" min-width="120">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.spec_price" type="number" min="0" />
              </template>
            </el-table-column>
            <el-table-column label="库存" align="center" min-width="120">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.spec_num" type="number" min="0" />
              </template>
            </el-table-column>
            <el-table-column size="mini" label="操作" align="center" min-width="120">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handelSpecDel(scope.$index)">删除</el-button>
                <el-button type="text" size="small" @click="handelSpecUp(scope.$index)">上移</el-button>
                <el-button type="text" size="small" @click="handelSpecDown(scope.$index)">下移</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="属性">
          <el-button icon="el-icon-plus" size="small" plain @click="handelAttrAdd"></el-button>
          <el-table size="mini" :data="form.attrs" border fit>
            <el-table-column label="名称" align="center" min-width="120">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.attr_name" />
              </template>
            </el-table-column>
            <el-table-column label="值" align="center" min-width="240">
              <template slot-scope="scope">
<!--                <el-tag :key="index" v-for="(tag,index) in scope.row.attr_val" size="medium" closable :disable-transitions="false" @close="handleClose(scope.row,index)">-->
<!--                  {{tag}}-->
<!--                </el-tag>-->
                <div :key="index" v-for="(item,index) in scope.row.attr_val" style="margin-bottom: 10px;">
                  <el-input style="display: inline-block;width: 150px;margin-right: 10px;" size="mini" v-model="item.name" placeholder="属性值名称" />
                  <el-input style="display: inline-block;width: 150px;margin-right: 10px;" size="mini" v-model="item.price" placeholder="属性值价格" @input="item.price=item.price.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
                  <el-button type="text" size="small" @click="handleClose(scope.row,index)">删除</el-button>
                  <el-button type="text" size="small" @click="handelAttrValUp(scope.row,index)">上移</el-button>
                  <el-button type="text" size="small" @click="handelAttrValDown(scope.row,index)">下移</el-button>
                </div>
<!--                <el-input class="input-new-tag" v-if="scope.$index == inputIndex && inputVisible" v-model="inputValue" ref="saveTagInput" size="mini" @keyup.enter.native="handleInputConfirm(scope.row)" @blur="handleInputConfirm(scope.row)">-->
<!--                </el-input>-->
<!--                <el-button v-else class="button-new-tag" size="small" @click="showInput(scope.$index)">+ 添加</el-button>-->
                <el-button class="button-new-tag" size="small" @click="handelAttrValAdd(scope.row)">+ 添加</el-button>
              </template>
            </el-table-column>
            <el-table-column size="mini" label="操作" align="center" min-width="120">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handelAttrDel(scope.$index)">删除</el-button>
                <el-button type="text" size="small" @click="handelAttrUp(scope.$index)">上移</el-button>
                <el-button type="text" size="small" @click="handelAttrDown(scope.$index)">下移</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      canteens: [],
      shops: [],
      categories: [],
      categoriesCopy: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        canteen_id: "",
        shop_id: "",
        category_id: "",
        is_show: ""
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        shop_id: "",
        category_id: "",
        goods_name: "",
        goods_description: "",
        goods_thumb: "",
        sell_price: 0,
        pack_fee: 0,
        weight: 0,
        goods_number: 0,
        content: "",
        banners: [],
        specs: [],
        attrs: [],
        is_require: 0,
      },
      ids: [],
      specsTemp: null,
      inputIndex: '',
      inputVisible: false,
      inputValue: "",
    };
  },
  created() {
    if (this.$route.query.shop_id) {
      this.listQuery.shop_id = parseInt(this.$route.query.shop_id);
      this.listQuery.category_id = parseInt(this.$route.query.category_id);
    }
    this.getList();
    this.getCanteenList();
    this.getShopList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    "listQuery.canteen_id": {
      handler(newValue, oldValue) {
        if (newValue !== "") {
          this.listQuery.shop_id = "";
          this.shops = [];
          this.listQuery.category_id = "";
          this.categories = [];
        }
        this.getShopList();
      },
      deep: true
    },
    "listQuery.shop_id": {
      handler(newValue, oldValue) {
        if (newValue === "") {
          this.categories = [];
          this.listQuery.category_id = "";
        } else if (newValue !== oldValue) {
          if (this.$route.query.category_id) {
            this.listQuery.category_id = parseInt(this.$route.query.category_id);
            this.$route.query.category_id = ""
          } else {
            this.listQuery.category_id = "";
          }
          this.getCategoryList();
        }
      },
      deep: true
    },
    "form.shop_id": {
      handler(newValue, oldValue) {
        if (newValue === "") {
          this.categoriesCopy = [];
          this.form.category_id = "";
        } else if (newValue !== oldValue) {
          this.getCategoryCopyList();
        }
      },
      deep: true
    },
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          shop_id: "",
          category_id: "",
          goods_name: "",
          goods_description: "",
          goods_thumb: "",
          sell_price: 0,
          pack_fee: 0,
          weight: 0,
          goods_number: 0,
          content: "",
          imgs: [],
          specs: [],
          attrs: [],
          is_require: 0,

        };
        this.categoriesCopy = []
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/goods/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCanteenList() {
      request({
        url: "/api/backend/canteen/list",
        method: "get",
        params: {
          page: 0,
          limit: 1000000,
          school_id: this.school_id
        }
      }).then(response => {
        this.canteens = response.data.data;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          canteen_id: this.listQuery.canteen_id,
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    getCategoryList() {
      request({
        url: "/api/backend/category/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          shop_id: this.listQuery.shop_id
        }
      }).then(response => {
        this.categories = response.data.data;
      });
    },
    getCategoryCopyList() {
      request({
        url: "/api/backend/category/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          shop_id: this.form.shop_id
        }
      }).then(response => {
        this.categoriesCopy = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        school_id: item.school_id,
        shop_id: item.shop_id,
        category_id: item.category_id,
        goods_name: item.goods_name,
        goods_description: item.goods_description,
        goods_thumb: item.goods_thumb,
        sell_price: item.sell_price,
        pack_fee: item.pack_fee,
        weight: item.weight,
        goods_number: item.goods_number,
        content: item.content,
        banners: item.banners,
        specs: JSON.parse(JSON.stringify(item.specs)),
        attrs: JSON.parse(JSON.stringify(item.attrs)),
        is_require: item.is_require,
      };
      this.dialogVisible = true;
    },
    handleShow(show) {
      let text = show == 1 ? "上架" : "下架";
      this.$confirm("确定要" + text + "选中商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/goods/isShow",
          method: "post",
          data: {
            ids: this.ids,
            is_show: show
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleDel() {
      this.$confirm("确定要删除选中商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/goods/del",
          method: "post",
          data: {
            ids: this.ids
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleCopy() {
      let item = null;
      this.list.forEach(element => {
        if (element.id === this.ids[0]) {
          item = element;
        }
      });
      this.form = {
        id: "",
        school_id: item.school_id,
        shop_id: item.shop_id,
        category_id: item.category_id,
        goods_name: item.goods_name,
        goods_description: item.goods_description,
        goods_thumb: item.goods_thumb,
        sell_price: item.sell_price,
        pack_fee: item.pack_fee,
        weight: item.weight,
        goods_number: item.goods_number,
        content: item.content,
        banners: item.banners,
        specs: JSON.parse(JSON.stringify(item.specs)),
        attrs: JSON.parse(JSON.stringify(item.attrs)),
      };
      this.form.specs.forEach(element => {
        element.id = ''
      });
      this.form.attrs.forEach(element => {
        element.id = ''
      });
      this.dialogVisible = true;
      console.log(this.form);

    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      if (this.form.id == 2 && !this.form.shop_id && !this.form.category_id) {
        this.$message({
          type: "warning",
          message: "请选择店铺和分类"
        });
        return;
      }
      if (this.form.goods_name <= 0) {
        this.$message({
          type: "warning",
          message: "请输入商品名称"
        });
        return;
      }
      if (!this.form.goods_thumb) {
        this.$message({
          type: "warning",
          message: "请上传封面"
        });
        return;
      }
      if (this.form.sell_price === '' || this.form.sell_price < 0) {
        this.$message({
          type: "warning",
          message: "请输入商品价格"
        });
        return;
      }
      if (this.form.pack_fee === '' || this.form.pack_fee < 0) {
        this.$message({
          type: "warning",
          message: "请输入包装费"
        });
        return;
      }
      if (this.form.goods_number === '' || this.form.goods_number < 0) {
        this.$message({
          type: "warning",
          message: "请输入库存"
        });
        return;
      }
      if (this.form.weight === '' || this.form.weight < 0) {
        this.$message({
          type: "warning",
          message: "请输入权重"
        });
        return;
      }
      if (this.form.specs.length > 0) {
        for (let index = 0; index < this.form.specs.length; index++) {
          const element = this.form.specs[index];
          element.rank = index + 1;
          if (!element.spec_name) {
            this.$message({
              type: "warning",
              message: "请输入规格名称"
            });
            return;
          }
          if (element.spec_price === '' || element.spec_price < 0) {
            this.$message({
              type: "warning",
              message: "请输入规格价格"
            });
            return;
          }
          if (element.spec_num === '' || element.spec_num < 0) {
            this.$message({
              type: "warning",
              message: "请输入规格库存"
            });
            return;
          }
        }
      }
      if (this.form.attrs.length > 0) {
        for (let index = 0; index < this.form.attrs.length; index++) {
          const element = this.form.attrs[index];
          element.rank = index + 1;
          if (!element.attr_name) {
            this.$message({
              type: "warning",
              message: "请输入属性名称"
            });
            return;
          }
          if (element.attr_val.length <= 0) {
            this.$message({
              type: "warning",
              message: "请输入属性值"
            });
            return;
          }
          // 属性值的名称与价格
          for (let i = 0; i < element.attr_val.length; i++) {
            const attr_val = element.attr_val[i];
            if (!attr_val.name) {
              this.$message({
                type: "warning",
                message: "请输入属性值名称"
              });
              return;
            }
            if (attr_val.price === "") {
              this.$message({
                type: "warning",
                message: "请输入属性值价格"
              });
              return;
            }
          }
        }
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/goods/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleDownload(item) {
      request({
        url: "/api/backend/goods/qrcode",
        method: "post",
        data: {
          goods_id: item.id
        }
      }).then(response => {
        let a = document.createElement("a");
        a.href = response.data;
        a.download = item.goods_name + ".png";
        a.click();
      });
    },
    handelSpecAdd() {
      this.form.specs.push({
        spec_name: "",
        spec_price: 0,
        spec_num: 0
      });
      console.log(this.form);
    },
    handelSpecDel(index) {
      this.form.specs.splice(index, 1);
    },
    handelSpecUp(index) {
      if (index == 0) {
        return;
      }
      this.form.specs.splice(index - 1, 0, this.form.specs.splice(index, 1)[0]);
    },
    handelSpecDown(index) {
      if (index >= this.form.specs.length - 1) {
        return;
      }
      this.form.specs.splice(index + 1, 0, this.form.specs.splice(index, 1)[0]);
    },
    handelAttrAdd(){
      this.form.attrs.push({
        attr_name: "",
        attr_val: [],
      });
    },
    handelAttrDel(index) {
      this.form.attrs.splice(index, 1);
    },
    handelAttrUp(index) {
      if (index == 0) {
        return;
      }
      this.form.attrs.splice(index - 1, 0, this.form.attrs.splice(index, 1)[0]);
    },
    handelAttrDown(index) {
      if (index >= this.form.attrs.length - 1) {
        return;
      }
      this.form.attrs.splice(index + 1, 0, this.form.attrs.splice(index, 1)[0]);
    },
    handleClose(item,index) {
      item.attr_val.splice(index, 1);
    },
    handelAttrValUp(item,index) {
      if (index == 0) {
        return;
      }
      item.attr_val.splice(index - 1, 0, item.attr_val.splice(index, 1)[0]);
    },
    handelAttrValDown(item,index) {
      if (index >= item.attr_val.length - 1) {
        return;
      }
      item.attr_val.splice(index + 1, 0, item.attr_val.splice(index, 1)[0]);
    },
    handelAttrValAdd(item){
      item.attr_val.push({
        name: "",
        price: 0,
      });
    },
    showInput(index) {
      this.inputIndex = index;
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm(item) {
      let inputValue = this.inputValue;
      if (inputValue) {
        item.attr_val.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag + .el-tag {
    margin-left: 10px;
    margin-top: 4px;
  }
  .button-new-tag {
    /*margin-left: 10px;*/
    height: 30px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    /*margin-top: 4px;*/
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
    margin-top: 4px;
  }
</style>
